<template>
  <v-container>
    <!-- <base-back /> -->
    <v-card class="pa-3" flat dark color="black">
      <v-card-text>
        <div class="layout column signin-up_title">
          Finish signing up
        </div>
      </v-card-text>
      <div class="signin-up_body">
        <v-text-field
          v-model="email"
          outlined
          persistent-hint
          name="email"
          :label="'Email'"
          type="text"
          hint="We’ll email you order confirmation and receipts"
          required
          disabled
        />
        <div class="policy-desc">
          By selecting Agree and continue below, I agree to One Channel’s <a href="https://www.onechannel.one/terms-service/" target="_blank"> Terms of Service</a> and <a href="https://www.onechannel.one/privacy-policy/" target="_blank">Privacy Policy</a>.
        </div>
        <div class="button-box">
          <v-btn block depressed medium class="tile btn-agree-policy" :disabled="agreeLoading" :loading="agreeLoading" @click="continueHandle">Agree and continue</v-btn>
        </div>
        <div class="marketing-message">
          One Channel will send you members-only deals, marketing emails and push notifications. You can opt out of receiving these at any time in your account settings or directly from the marketing notification.
        </div>
        <v-checkbox
          v-model="receiveMessage"
          color="purple accent-1"
          class="marketing-checkbox"
          label="I don’t want to receive marketing messages from One Channel."
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'SignUp',
  props: {
    email: {
      type: String,
      required: true,
      default: ''
    },
    agreeHandle: {
      type: Function,
      required: true,
      default: () => {

      }
    }
  },
  data: () => ({
    receiveMessage: false,
    agreeLoading: false
  }),
  methods: {
    continueHandle() {
      this.agreeLoading = true
      this.agreeHandle({ is_privacy_agreement: true, is_receive_message: !this.receiveMessage }, 'sign_up').then(result => {}).finally(() => {
        this.agreeLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.signin-up_title{
    font-weight:800;
    text-align: center;
    color: white;
}

.signin-up_body{
    margin-top: 20px;
    .policy-desc{
        margin-top: 20px;
        a{
            color: rgb(255, 81, 255);
            text-decoration: none;
        }
    }
    .button-box{
        margin-top: 40px;
        .v-btn{
            background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
            font-weight: bold;
            letter-spacing: 0.05em;
            text-transform: none !important;
        }
    }
    .marketing-message{
        margin-top: 40px;
    }

    .marketing-checkbox{
        color: rgb(255, 81, 255);
    }
}

</style>
